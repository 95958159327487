// ! CSS Variables (Custom Properties) are not IE-friendly
// https://caniuse.com/#feat=css-variables
// let's Sass do the job instead

// customise/enlarge this small selection as needed

// hex to rgba online converter http://www.hex2rgba.devoth.com/

// backgrounds, texts and borders
$bf-white: #fff; // rgba(255,255,255,1)
$bf-light: #f0f0f0; // rgba(240,240,240,1)
$bf-gray-light-extra: #e9e9e9; // rgba(233,233,233,1)
$bf-gray-light: #ccc; // rgba(204,204,204,1)
$bf-gray: #999; // rgba(153,153,153,1)
$bf-gray-dark: #666; // rgba(102,102,102,1)
$bf-gray-dark-extra: #333; // rgba(51,51,51,1)
$bf-dark: #222; // rgba(34,34,34,1)
$bf-dark-extra: #111; // rgba(17,17,17,1)
$bf-black: #000; // rgba(0,0,0,1)

// <mark> and <ins>
$bf-yellow-light: #ff9; // rgba(255,255,153,1)
$bf-yellow: #ff0; // rgba(255,255,0,1)

// anchors and accent
$bf-blue: #007bff; // rgba(0,123,255,1)
$bf-blue-light: #0056b3; // rgba(0,86,179,1)
$bf-blue-light-extra: #bed8fe; //rgba(190, 216, 254, 1)

// invalid forms
$bf-red: #ff0000; // rgba(255,0,0,1)
