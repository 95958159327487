a {
  color: $bf-blue; // bootstrap blue
  text-decoration: none;

  // IE10+: Remove grey background on active links
  background-color: transparent;
  
  &:hover,
  &:focus {
    text-decoration: underline;
    color: $bf-blue-light;
  }
}

// this is taken from Bootstrap's reboot
// https://getbootstrap.com/docs/4.4/content/reboot/
//
// ':not' is IE9+
// https://caniuse.com/#feat=mdn-css_selectors_not
a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus,
a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}
