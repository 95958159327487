// customise this spacing selection as needed

// the REM unit is the default,
// preceded by the PX unit as fallback for old browsers (IE)
// https://caniuse.com/#search=rem
//
// e.g.
// html { font-size: 62.5%; }
// body { font-size: 16px; }
// h1 { font-size: 30px; font-size: 3rem; }

$bf-spacing-sm-px: 2.5px;
$bf-spacing-md-px: 5px;
$bf-spacing-lg-px: 10px;
$bf-spacing-xl-px: 20px;
$bf-spacing-xxl-px: 30px;

$bf-spacing-sm-rem: .25rem;
$bf-spacing-md-rem: .5rem;
$bf-spacing-lg-rem: 1rem;
$bf-spacing-xl-rem: 2rem;
$bf-spacing-xxl-rem: 3rem;

// ! grid system only
$bf-spacing-grid-gutter-px: 15px;
$bf-spacing-grid-gutter-rem: 1.5rem;