blockquote {
  margin: 0 0 $bf-spacing-xl-px 0;
  margin-bottom: $bf-spacing-xl-rem;
  padding-left: 0;
  font-style: italic;
}

blockquote p {
  margin-bottom: 0;
}

blockquote small {
  color: $bf-gray;
  display: block;
}

blockquote small:before {
  content: '\2014 \00A0';
}

q {
  quotes: '\201C''\201D''\2018''\2019';
}
