// styling input range in modern browsers safely using the @supports CSS-at-rule
// https://developer.mozilla.org/en-US/docs/Web/CSS/@supports
//
// standalone CodePen demo https://codepen.io/marcop135/pen/JjGrNMx

@supports (appearance: none) {
  [type='range'] {
    appearance: none;
    width: 100%;
    height: 1.4rem;
  }

  [type='range']::-webkit-slider-thumb {
    border: 0;
    appearance: none;

    width: 1.5rem;
    height: 1.5rem;
    margin-top: -0.25rem;
    background-color: $bf-blue;
    border-radius: 1.5rem;
    transition: background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:active {
      background-color: $bf-blue;
    }
  }

  [type='range']::-moz-range-thumb {
    border: 0;
    appearance: none;

    width: 1.5rem;
    height: 1.5rem;
    background-color: $bf-blue;
    border-radius: 1.5rem;
    transition: background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:active {
      background-color: $bf-blue;
    }
  }

  [type='range']::-webkit-slider-runnable-track {
    color: transparent;
    border-color: transparent;

    width: 100%;
    height: 0.65rem;
    cursor: pointer;
    background-color: $bf-gray-light-extra;
  }

  [type='range']::-moz-range-track {
    color: transparent;
    border-color: transparent;

    width: 100%;
    height: 0.65rem;
    cursor: pointer;
    background-color: $bf-gray-light-extra;
  }

  [type='range']:focus {
    outline: none;

    &::-webkit-slider-thumb {
      box-shadow: 0 0 0 0.1rem $bf-white, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }

    &::-moz-slider-thumb {
      box-shadow: 0 0 0 0.1rem $bf-white, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }
  }

  [type='range']::-moz-focus-outer {
    border: 0;
  }

  [type='range']:focus::-moz-range-thumb {
    box-shadow: 0 0 0 0.1rem $bf-white, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }

  // disabled

  [type='range']:disabled {
    pointer-events: none;

    &::-webkit-slider-thumb {
      background-color: $bf-gray-light;
    }

    &::-moz-slider-thumb {
      background-color: $bf-gray-light;
    }
  }
}
