pre {
  margin: 0 0 $bf-spacing-xl-px 0;
  margin: 0 0 $bf-spacing-xl-rem 0;

  display: block;
  overflow: auto;
  word-break: break-all; // webkit
  word-wrap: break-word;
  white-space: pre;
  hyphens: none;

  // this is a not standard CSS property, so it's for IE only
  // https://developer.mozilla.org/en-US/docs/Web/CSS/-ms-overflow-style
  //
  // Disable auto-hiding scrollbar in legacy Edge
  // https://github.com/twbs/bootstrap/pull/30685
  -ms-overflow-style: auto;
}

pre,
code,
kbd,
samp {
  font-family: 

  /* macOS 1.10+ */ 'Menlo', /* Windows 6+ */ 'Consolas',
    /* Android 4+ */ 'Roboto Mono', /* Ubuntu 1.10+ */ 'Ubuntu Monospace',
    /* KDE Plasma 5+ */ 'Noto Mono', /* KDE Plasma 4+ */ 'Oxygen Mono',
    /* Linux/OpenOffice fallback */ 'Liberation Mono', /* fallback */ monospace,
    /* emoji fonts */ 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';

  // a monospaced text font should looks bigger, so a smaller font-size
  // is preferred
  font-size: 0.875em;
}

code {
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}
