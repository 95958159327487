$bf-font-sans-serif: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
  Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', Helvetica, Arial, sans-serif,
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

$bf-font-serif: Georgia, Times, 'Times New Roman', serif;

$bf-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono',
  'Courier New', monospace;

$bf-body-font-size-px: 16px;
$bf-body-font-size-rem: 1.6rem;
$bf-body-line-height: 1.5;
