table {
  max-width: 100%;
  border-collapse: collapse;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

thead th {
  vertical-align: bottom;
}

th,
td {
  vertical-align: top;
}

th {
  text-align: inherit;
  font-weight: 700;
}

caption {
  padding-top: $bf-spacing-md-px;
  padding-top: $bf-spacing-md-rem;
  padding-bottom: $bf-spacing-md-px;
  padding-bottom: $bf-spacing-md-rem;
  color: $bf-gray;
  text-align: left;
  caption-side: bottom;
  line-height: 1.375;
  font-size: 90%;
}
