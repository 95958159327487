[type='email'],
[type='number'],
[type='password'],
[type='tel'],
[type='search'],
[type='text'],
[type='date'],
[type='datetime-local'],
[type='month'],
[type='week'],
[type='time'],
[type='url'],
input[list],
select,
textarea {
  background-color: $bf-white;
  border: 1px solid $bf-gray;
  padding: $bf-spacing-md-px ($bf-spacing-lg-px * 0.75);
  padding: $bf-spacing-md-rem ($bf-spacing-lg-rem * 0.75);
}

// Old browsers and Opera Mini don't fully support all type of inputs
// e.g. [type="range"]
// so, let's try to add styles carefully and selective
[type='email'],
[type='number'],
[type='password'],
[type='tel'],
[type='search'],
[type='text'],
[type='date'],
[type='datetime-local'],
[type='month'],
[type='week'],
[type='time'],
[type='url'],
input[list],
select,
textarea,
[type='radio'],
[type='checkbox'] {
  &:active,
  &:focus {
    transition: border 0.2s, background-color 0.2s, box-shadow 0.2s;
    border-color: $bf-blue;
    box-shadow: 0 0 0 3px rgba(0, 86, 179, 0.25);
  }
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  color: $bf-dark;
  padding: calc($bf-spacing-xl-px / 3) (calc($bf-spacing-xl-px / 3) * 2); // 8px 16px
  padding: calc($bf-spacing-xl-rem / 3) (calc($bf-spacing-xl-rem / 3) * 2);
  background: $bf-gray-light;
  transition: color 0.2s, background 0.2s, box-shadow 0.2s;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    color: $bf-dark;
    background: darken($bf-gray-light, 10%);
  }

  &:active,
  &:focus {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.15);
  }
}

// disabled groups
fieldset[disabled] button,
fieldset[disabled] input,
fieldset[disabled] input:after,
fieldset[disabled] input:before,
fieldset[disabled] input:checked,
fieldset[disabled] select,
fieldset[disabled] textarea,
fieldset[disabled] label,

// disabled singles
button[disabled],
input[disabled],
input[disabled]:after,
input[disabled]:before,
input[disabled]:checked,
select[disabled],
textarea[disabled],
label[disabled],

// pseudo class
button:disabled,
input:disabled,
input:disabled:after,
input:disabled:before,
input:disabled:checked,
select:disabled,
textarea:disabled,
label:disabled,

// aria 
button[aria-disabled="true"],
input[aria-disabled="true"],
input[aria-disabled="true"]:after,
input[aria-disabled="true"]:before,
input[aria-disabled="true"]:checked,
select[aria-disabled="true"],
textarea[aria-disabled="true"],
label[aria-disabled="true"] {
  cursor: default !important; // fallback
  cursor: not-allowed !important;
  pointer-events: none !important;
  user-select: none !important;
  box-shadow: none !important;
  opacity: 0.5 !important;
}
