// heavily inspired by '2020 toggles' by Aaron Iker
// https://css-tricks.com/custom-styling-form-inputs-with-modern-css-features/
// https://codepen.io/aaroniker/pen/ZEYoxEY?editors=0100
//
// https://developer.mozilla.org/en-US/docs/Web/CSS/@supports
// https://caniuse.com/#feat=mdn-css_at-rules_supports_selector
//
// Anyway are safe and future-friendly because more browser compatibility
// will come

@supports (appearance: none) {
  [type='checkbox'],
  [type='radio'] {
    appearance: none;

    height: 1.8rem;
    width: 1.8rem;

    vertical-align: top;
    position: relative;

    border: 1px solid $bf-gray;

    + label {
      line-height: 1.8rem;
      vertical-align: top;
      margin-left: $bf-spacing-md-px;
      margin-left: $bf-spacing-md-rem;
    }

    // add support for RTL languages
    // Read more
    // 1. http://bootstrap.rtlcss.com
    // 2. https://rtlstyling.com/
    *[dir='rtl'] & + label,
    &[dir='rtl'] + label {
      margin-left: 0;
      margin-right: $bf-spacing-md-px;
      margin-right: $bf-spacing-md-rem;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
    }

    &:checked {
      background-color: $bf-blue;
      border-color: $bf-black;
    }
  }

  [type='radio'] {
    &:after {
      width: 1.8rem;
      height: 1.8rem;

      left: -0.05rem;
      top: -0.05rem;

      border-radius: 50%;
      background: $bf-white;

      transform: scale(0.35);
    }
  }

  [type='checkbox'] {
    &:after {
      width: 0.6rem;
      height: 0.8rem;

      border: 2px solid $bf-white;
      border-top: 0;
      border-left: 0;

      left: 35%;
      top: 20%;

      transform: rotate(43deg);
    }
  }
}
