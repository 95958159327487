menu,
ol,
ul,
dl {
  margin: 0 0 $bf-spacing-xl-px 0;
  margin: 0 0 $bf-spacing-xl-rem 0;
  padding: 0;
}

menu ul,
menu ol,
ul ul,
ul ol,
ol ol,
ol ul,
dd {
  margin-bottom: 0;
}

ul,
ol {
  margin-left: $bf-spacing-xl-px;
  margin-left: $bf-spacing-xl-rem;
}

nav ol,
nav ul {
  list-style: none;
  list-style-image: none;
  margin-left: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-left: 0;
}

// taken from https://github.com/jensimmons/cssremedy
//
// 1. Add zero-width-space to prevent VoiceOver disable
// 2. Absolute position ensures no extra space
// See: https://unfetteredthoughts.net/2017/09/26/voiceover-and-list-style-type-none/
nav li:before {
  content: '\200B';
  position: absolute;
}
